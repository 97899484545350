<template>
    <div>
        <div v-if="loaded" class="card card-profile animate__animated animate__fadeIn">
            <div class="card-heading border-bottom with-cta">
                <p class="title">{{$t('profile.workProvider.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question alternate-name row">
                        <div class="label-container">
                            <p>{{$t('profile.workProvider.card.alternateName')}}</p>
                            <FormError :data="validation.alternateName"/>
                        </div>

                        <input  :class="['input input-text', error('alternateName')]"
                                v-model="data.alternateName" type="text"
                                @keyup.enter="updateWorkProviderProfile()">
                    </div>
                    
                    <div class="question logo row">
                        <div class="label-container">
                            <p>{{$t('profile.workProvider.card.logo')}}</p>
                        </div>

                        <img class="uploaded-logo" v-if="data.logo_url && data.logo_url !== 'none'" :src="data.logo_url" alt="company logo">
                        <p class="no-logo" v-else>Aucun logo</p>
                    </div>

                    <div class="question allowed-contact-methods allowed-contact-methods-work-provider column">
                        <div class="label-container">
                            <p>{{$t('allowed_contact_methods.title')}}</p>
                            <p class="subtitle" v-html="$t('allowed_contact_methods.subtitle')"></p>
                        </div>

                        <div class="row">
                            <div class="input-container label-on-top">
                                <p id="phone-number-label" :class="error('phone_number')">{{ $t('allowed_contact_methods.fields.phone_number.title') }}</p>
                                <the-mask id="phone-number-input" mask="+1 (###) ###-####" v-model="data.allowed_contact_methods.phone.value" :class="['input input-text', error('phone_number')]" placeholder="+1 (123) 456-7890" @keyup.enter="finalize()"/>
                            </div>
                            
                            <div class="input-container label-on-top w-auto">
                                <p id="ext-label">{{ $t('allowed_contact_methods.fields.ext.title') }}</p>
                                <the-mask id="ext-input" mask="############" v-model="data.allowed_contact_methods.phone.extension" :class="['input input-text w-100']" @keyup.enter="finalize()"/>
                            </div>

                            <div class="input-container label-on-top">
                                <p>{{ $t('allowed_contact_methods.fields.email.title') }}</p>
                                <input id="email-input" :class="['input input-text disabled']" v-model="data.allowed_contact_methods.email.value" type="text">
                            </div>
                        </div>
                        
                        <FormError :data="validation.phone_number" :classes="[{desktop: true}, {responsive: true}]" />

                        <div class="checkbox-container" @click="data.allowed_contact_methods.phone.supports_texts = !data.allowed_contact_methods.phone.supports_texts">
                            <input id="checkbox-text-message" type="checkbox" :class="[{ checked: data.allowed_contact_methods.phone.supports_texts }]" v-model="data.allowed_contact_methods.phone.supports_texts">
                            <p class="label">{{$t('allowed_contact_methods.text-messaging')}}</p>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <button class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updateWorkProviderProfile()">{{$t('cards.generic.submit.label')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bus } from '../../../main';

import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: 'WorkProviderProfileCard',

    components: {
        FormError
    },

    data() {
        return {
            loaded: false,

            data: {},
            
            formValidated: false,
            loading: false,

            validation: {
                alternateName: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                phone_number: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },
        }
    },

    computed: {
        ...mapGetters([
            'scopes'
        ]),

        canDelete: function() {
            const self = this; return self.scopes.includes('worker') || self.scopes.includes('volunteer');
        }
    },

    methods: {
        ...mapActions([
            'setToken'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
            });

            if(!self.data.alternateName) {
                self.validation.alternateName.error = true;
            }

            if(!self.$utils._validate_phone_number(self.data.allowed_contact_methods.phone.value)) {
                self.validation.phone_number.error = true;
            }

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        getWorkProviderProfile: function() {
            const self = this;

            self.$axios
                .get(`/work-provider`)
                .then(response => {
                    if(response.status == 200){
                        self.data = response.data;
                        
                        self.$nextTick(() => {
                            self.$emit('loaded');
                            self.loaded = true;

                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        updateWorkProviderProfile: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                self.$axios
                    .put(`/work-provider`, self.data)
                    .then(response => {
                        if(response.status == 200){
                            const data = response.data;
                            self.setToken(data);

                            self.$toasted.success(
                                self.$t('notifications.workProviderProfileUpdateSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.$nextTick(() => {
                                self.loading = false;
                                self.formValidated = false;
                            })
                        }
                    })
                    .catch(() => {
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this; self.getWorkProviderProfile();
    }
}
</script>

