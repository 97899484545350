<template>
    <div class="modal email" :class="{active: active}">
        <div class="modal-card">
            <div class="modal-body">
                <div class="input-container">
                    <p>{{$t('profile.updateEmail.card.email.current.title')}}</p>

                    <input  :class="['input input-text', error('currentEmail')]"
                            v-model="data.currentEmail" type="text"
                            @keyup.enter="modifyEmail()">

                    <FormError :data="validation.currentEmail" />
                </div>

                <div class="input-container">
                    <p>{{$t('profile.updateEmail.card.email.new.title')}}</p>

                    <input  :class="['input input-text', error('newEmail')]"
                            v-model="data.newEmail" type="text"
                            @keyup.enter="modifyEmail()">

                    <FormError :data="validation.newEmail" />
                </div>
            </div>

            <div class="cta-container">
                <div class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="modifyEmail()">{{$t('profile.updateEmail.card.submit.label')}}</div>
                <div class="cta cta-neutral cta-slim" @click="closeAndResetModal()">{{$t('language.cancel')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: 'UpdateEmailModal',

    components: {
        FormError
    },

    props: {
        active: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            data: {
                currentEmail: '',
                newEmail: ''
            },

            validation: {
                currentEmail: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                newEmail: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },

            formValidated: false,
            loading: false,
        }
    },

    watch: {
        active: function() {
            const self = this;
            if(self.active) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    methods: {
        ...mapActions([
            'logout'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;

            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
                self.validation[val].key = 'errors.validation.fieldMandatory'

                if(!self.data[val] )
                    self.validation[val].error = true;
                else if(!self.$utils._validateEmail(self.data[val])) {
                    self.validation[val].error = true;
                    self.validation[val].key = 'errors.validation.emailInvalid';
                }
            });

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });

        },

        modifyEmail: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                const data = {
                    currentEmail: self.data.currentEmail,
                    newEmail: self.data.newEmail
                }

                self.$axios
                    .put(`/user/email`, data)
                    .then(response => {
                        if(response.status == 200){
                            self.$toasted.success(
                                self.$t('notifications.emailUpdateSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.logout(self.$router)
                            
                            self.$nextTick(() => {
                                self.formValidated = false;
                                self.closeAndResetModal();
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        closeAndResetModal: function() {
            const self = this;

            self.loading = false;
            self.data.currentEmail = '';
            self.data.newEmail = '';

            self.$emit('closeModal');
        },
        
        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    }
}
</script>