<template>
    <div v-if="loaded" class="card card-settings animate__animated animate__fadeIn">
        <div class="card-heading border-bottom with-cta">
            <p v-if="type === 'work-provider'" class="title">{{$t('profile.notificationPreferences.card.title.default')}}</p>
            <p v-else class="title">{{$t(`profile.notificationPreferences.card.title.${type}`)}}</p>
        </div>

        <div class="card-body">
            <div class="question-wrapper">
                <div class="question" v-for="(value, key) in data" :key="key">
                        <div class="label-container">
                            <p>{{$t(`profile.notificationPreferences.card.preferences.${type}.${key}.label`)}}</p>
                            <p class="subtitle" v-if="key === 'notifyOnNewOffer'">{{$t(`profile.notificationPreferences.card.preferences.${type}.${key}.subtitle`)}}</p>
                        </div>
                        
                        <input v-model="data[key]" type="checkbox">
                </div>
            </div>
            
            <div class="card-footer">
                <button class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updateNotificationPreferences()">{{$t('cards.generic.submit.label')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '../../../main';

export default {
    name: 'NotificationPreferencesCard',

    props: {
        type: String
    },

    data() {
        return {
            data: {},

            loaded: false,
            loading: false,
        }
    },

    methods: {
        getNotificationPreferences: function() {
            const self = this;
            
            self.$axios
                .get(`/notification-preferences/${self.type}`)
                .then(response => {
                    if(response.status == 200){
                        self.data = response.data;

                        self.$nextTick(() => {
                            self.loaded = true;
                            self.$emit('loaded');

                            bus.$emit('reset_heading_container_margin', true);
                        })                    
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });

        },

        updateNotificationPreferences: function() {
            const self = this;
            
            self.loading = true;
            const data = {
                type: self.type,
                notificationPreferences: self.data
            }

            self.$axios
                .put(`/notification-preferences`, data)
                .then(response => {
                    if(response.status == 200) {
                        self.$toasted.success(
                            self.$t('notifications.preferencesUpdateSuccess'),
                            { icon: 'circle-check' }
                        );

                        self.$nextTick(() => {
                            self.loading = false;
                            self.formValidated = false;
                        })      
                    }
                })
                .catch(() => {
                    self.loading = false;
                    self.formValidated = false;
                });
        }
    },

    mounted() {
        const self = this; self.getNotificationPreferences();
    }
}
</script>

