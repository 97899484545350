<template>
    <div v-if="loaded" class="card card-profile animate__animated animate__fadeIn">
        <div class="card-heading border-bottom">
            <p class="title">{{$t('profile.user.card.title')}}</p>
        </div>

        <div class="card-body">
            <div class="question-wrapper">
                <div class="question email disabled row">
                    <div class="label-container">
                        <p>{{$t('profile.user.card.email.title')}}</p>
                    </div>

                    <input class="input input-text" v-model="data.email" type="text">
                </div>

                <div class="question postal-code row">
                    <div class="label-container">
                        <p>{{$t('profile.user.card.postalCode.title')}}</p>
                        
                        <FormError :data="validation.postalCode" />
                    </div>

                    <input class="input input-text" 
                        :class="error('postalCode')"
                        @input="data.postalCode = $event.target.value.toUpperCase()"
                        :value="data.postalCode.toUpperCase()" type="text">
                </div>

                <div class="question preferred-language row">
                    <div class="label-container">
                        <p>{{$t('profile.user.card.preferredCommunicationLanguage.title')}}</p>

                        <FormError :data="validation.preferredCommunicationLanguage" />
                    </div>

                    <multiselect    v-model="data.preferredCommunicationLanguage" 
                                    :options="preferredCommunicationLanguageOptions"
                                    :close-on-select="true"
                                    :multiple="false"
                                    :allowEmpty="false"
                                    :searchable="false"
                                    :class="error('preferredCommunicationLanguage')"

                                    :placeholder="$t('profile.user.card.preferredCommunicationLanguage.placeholder')"
                                    :select-label="$t('language.multiselect.label.select')"
                                    :selected-label="$t('language.multiselect.label.selected')"
                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                    :custom-label="$utils._translateLanguageToLongVersion"> 
                                    
                                    <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                    </multiselect>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updateUserProfile()">{{$t('cards.generic.submit.label')}}</button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { bus } from '../../../main';

import FormError from '../../../components/Utils/FormError.vue';

export default {
    name: 'UserProfileCard',

    components: {
        FormError
    },

    data() {
        return {
            loaded: false,
            
            data: {
                email: '',
                postalCode: '',
                preferredCommunicationLanguage: ''
            },

            validation: {
                postalCode: {
                    error: false,
                    key: 'errors.validation.postalCodeFormatInvalid'
                },

                preferredCommunicationLanguage: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },

            formValidated: false,
            loading: false,

            preferredCommunicationLanguageOptions: ['fr', 'en'],
        }
    },

    methods: {
        ...mapActions([
            'setToken'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false

                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            if(!self.$utils._validate_postal_code_format(self.data.postalCode))
                self.validation.postalCode.error = true;

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        getUserProfile: function() {
            const self = this;

            self.$axios
                .get(`/user/profile`)
                .then(response => {
                    if(response.status == 200){
                        self.data = response.data;

                        self.$nextTick(() => {
                            self.$emit('loaded');
                            self.loaded = true;

                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                    
                    self.$nextTick(() => {
                        self.$emit('loaded');
                        self.loaded = true;
                    })
                });
        },

        updateUserProfile: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;
                self.$axios
                    .put(`/user`, self.data)
                    .then(response => {
                        if(response.status == 200){
                            self.setToken(response.data);

                            self.$toasted.success(
                                self.$t('notifications.profileUpdateSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.$nextTick(() => {
                                self.loading = false;
                                self.formValidated = false;
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this; self.getUserProfile();
    }
}
</script>

