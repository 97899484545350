<template>
    <div class="modal delete-account top" :class="{ active }">
        <div class="modal-card">
            <p class="title">{{$t('profile.deleteAccount.card.title')}}</p>
            <p class="description" v-html="$t(`profile.deleteAccount.card.content.${type}`)"></p>
            <p class="description" v-if="type === 'worker'">{{$t('profile.deleteAccount.card.alternative')}}</p>
                
            <div class="modal-body">
                <div class="checkbox-container">
                    <p class="title">{{$t('profile.deleteAccount.card.survey.title')}}</p>
                    
                    <div class="checks">
                        <div class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.both_too_complicated`)}}</p>
                            <input id="delete_account_modal_reason" type="checkbox" v-model="reasons.both_too_complicated">
                        </div>

                        <div class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.both_notifications`)}}</p>
                            <input id="delete_account_modal_reason" type="checkbox" v-model="reasons.both_notifications">
                        </div>

                        <div v-if="type === 'worker'" class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.worker_not_working_now`)}}</p>
                            <input type="checkbox" v-model="reasons.worker_not_working_now">
                        </div>

                        <div v-if="type === 'worker'" class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.worker_cant_find_job`)}}</p>
                            <input type="checkbox" v-model="reasons.worker_cant_find_job">
                        </div>
                        
                        <div v-if="type === 'work_provider'" class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.work_provider_not_looking_now`)}}</p>
                            <input type="checkbox" v-model="reasons.work_provider_not_looking_now">
                        </div>

                        <div v-if="type === 'work_provider'" class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.work_provider_too_expensive`)}}</p>
                            <input type="checkbox" v-model="reasons.work_provider_too_expensive">
                        </div>

                        <div v-if="type === 'work_provider'" class="input-container">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.choices.work_provider_cant_find_workers`)}}</p>
                            <input type="checkbox" v-model="reasons.work_provider_cant_find_workers">
                        </div>

                        <FormError :data="validation.reasons" />

                        <div class="input-container other">
                            <p class="label">{{$t(`profile.deleteAccount.card.survey.other`)}}</p>
                            <textarea class="text-area" v-model="other" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta-container">
                <div id="delete_account_modal_delete" class="cta cta-error dark cta-slim" @click="deleteAccount()" :class="{ loading: loading }">{{$t('profile.deleteAccount.card.delete')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="closeAndResetModal()">{{$t('language.cancel')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormError from '../../Utils/FormError.vue';

export default {
    name: 'DeleteAccountModal',
    
    components: {
        FormError
    },

    props: {
        active: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            reasons: {
                both_too_complicated: false,
                both_notifications: false,
                worker_not_working_now: false,
                worker_cant_find_job: false,
                work_provider_not_looking_now: false,
                work_provider_too_expensive: false,
                work_provider_cant_find_workers: false,
            },

            other: '',

            reasons_array: [],

            form_validated: false,
            validation: {
                reasons: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },
            },
        }
    },

    watch: {
        active: function() {
            const self = this;
            if(self.active) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    computed: {
        ...mapGetters([
            'is_person'
        ]),

        type: function() {
            const self = this;
            return self.is_person ? 'worker' : 'work_provider';
        }
    },

    methods: {
        ...mapActions([
            'logout'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.form_validated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
            });

            if(!self.other && self.reasons_array.length === 0) {
                self.form_validated = false;
                self.validation['reasons'].error = true;
            }
        },

        deleteAccount: function() {
            const self = this;

            self.reasons_array = [];
            Object.keys(self.reasons).forEach(key => {
                if(self.reasons[key]) { self.reasons_array.push(key) }
            })

            self.validate_mandatory_fields();
            if(self.form_validated) {
                self.loading = true;
                const data = {
                    reasons: self.reasons_array,
                    other: self.other,
                    type: self.type
                }
                
                self.$axios
                    .post('/user/delete', data)
                    .then(response => {
                        if(response.status == 200){
                            self.logout(self.$router)
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        closeAndResetModal: function() {
            const self = this;

            self.loading = false;
            self.form_validated = false;
            self.validation.reasons.error = false;

            self.other = '';
            Object.keys(self.reasons).forEach(key => {
                self.reasons[key] = false
            })

            self.$emit('closeModal');
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    }
}
</script>