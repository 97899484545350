<template>
    <div v-if="loaded" class="card card-profile animate__animated animate__fadeIn">
        <div class="card-heading border-bottom with-cta">
            <p class="title">{{$t(`profile.${type}.card.title.alternate`)}}</p>
        </div>

        <div class="card-body">
            <div class="question-wrapper">
                <div class="question rc row">
                    <div class="label-container nomargin-bottom">
                        <p>{{$t(`profile.${type}.card.retirement_calculator.title`)}}</p>
                        <p class="subtitle" v-html="$t(`profile.${type}.card.retirement_calculator.subtitle`)"></p>
                    </div>

                    <span class="cta cta-outline cta-slim dark" @click="$utils._navigate_to_name('retirement-calculator-embedded')">{{ $t(`profile.${type}.card.retirement_calculator.cta`) }}</span>
                </div>

                <div v-if="data.allowed_contact_methods" class="question allowed-contact-methods allowed-contact-methods-worker column">
                    <div class="label-container">
                        <p class="title">{{$t('getstarted.steps.seven.title')}}</p>
                        <p class="subtitle" v-html="$t('getstarted.steps.seven.subtitle')"></p>
                    </div>

                    <div class="row" v-match-heights="{el: ['.mh']}">
                        <div class="buttons-container mh">
                            <span id="allowed-contact-method-app" :class="['cta cta-select no-hover', { active: true }]" @click="display_notice()">
                                <font-awesome-icon class="success" :icon="['fa', 'circle-check']" />
                                <p>{{ $t('getstarted.steps.seven.buttons.app') }}</p>
                            </span>

                            <span id="allowed-contact-method-email" :class="['cta cta-select', { active: data.allowed_contact_methods.email.allowed }]" @click="data.allowed_contact_methods.email.allowed = !data.allowed_contact_methods.email.allowed">
                                <font-awesome-icon v-if="data.allowed_contact_methods.email.allowed" class="success" :icon="['fa', 'circle-check']" />
                                <span v-else class="circle"></span>                                    
                                <p>{{ $t('getstarted.steps.seven.buttons.email') }}</p>
                            </span>

                            <span id="allowed-contact-method-phone" :class="['cta cta-select nomargin-bottom', { active: data.allowed_contact_methods.phone.allowed }]" @click="data.allowed_contact_methods.phone.allowed = !data.allowed_contact_methods.phone.allowed">
                                <font-awesome-icon v-if="data.allowed_contact_methods.phone.allowed" class="success" :icon="['fa', 'circle-check']" />
                                <span v-else class="circle"></span>
                                <p>{{ $t('getstarted.steps.seven.buttons.phone') }}</p>
                            </span>
                        </div>
                        
                        <span v-if="data.allowed_contact_methods.phone.allowed" class="divider mh responsive desktop"></span>
                        
                        <div id="allowed-contact-method-phone-input-container" v-if="data.allowed_contact_methods.phone.allowed" class="phone-number-container">
                            <div class="input-container label-on-top">
                                <p :class="error('phone_number')">{{ $t('allowed_contact_methods.fields.phone_number.title') }}</p>
                                <the-mask id="allowed-contact-method-phone-input" mask="+1 (###) ###-####" v-model="data.allowed_contact_methods.phone.value" :class="['input input-text', error('phone_number')]" placeholder="+1 (123) 456-7890"/>
                            </div>

                            <FormError :data="validation.phone_number" :classes="[{desktop: true}, {responsive: true}]" />

                            <span id="allowed-contact-method-phone-supports-texts" :class="['cta cta-select', { active: data.allowed_contact_methods.phone.supports_texts }]" @click="data.allowed_contact_methods.phone.supports_texts = !data.allowed_contact_methods.phone.supports_texts">
                                <font-awesome-icon v-if="data.allowed_contact_methods.phone.supports_texts" class="success" :icon="['fa', 'circle-check']" />
                                <span v-else class="circle"></span>
                                <p>{{ $t('getstarted.steps.seven.supports_texts') }}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updateProfile()">{{$t('cards.generic.submit.label')}}</button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bus } from '../../../main';

import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: 'CommonProfileCard',

    components: {
        FormError
    },

    props: {
        type: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            loaded: false,

            data: {},

            validation: {
                age_group: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                phone_number: {
                    error: false,
                    name: 'worker-phone-number',
                    key: 'errors.validation.phoneNumberFormatInvalid'
                }
            },

            age_group_options: ['below_55', '55_and_above'],

            formValidated: false,
            loading: false,
        }
    },

    computed: {
        ...mapGetters([
            'email'
        ]),
    },

    watch: {
        'data.allowed_contact_methods.phone.allowed': function() {
            const self = this;
            if(!self.data.allowed_contact_methods.phone.allowed) {
                self.data.allowed_contact_methods.phone.value = '';
                self.data.allowed_contact_methods.phone.supports_texts = false;
                self.validation.phone_number.error = false;
            } else {
                self.data.allowed_contact_methods.phone.externaly_validated = false;
            }
        },

        'data.allowed_contact_methods.email.allowed': function() {
            const self = this;
            if(self.data.allowed_contact_methods.email.allowed) {
                self.data.allowed_contact_methods.email.value = self.email;
                self.data.allowed_contact_methods.email.externaly_validated = false;
            }
        },
    },

    methods: {
        ...mapActions([
            'setToken',
        ]),

        validate_mandatory_fields: function() {
            const self = this;
            
            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
            });

            if(self.data.allowed_contact_methods.phone.allowed) {
                if(!self.$utils._validate_phone_number(self.data.allowed_contact_methods.phone.value)) {
                    self.validation.phone_number.error = true;
                    self.formValidated = false;
                }
            }
        },

        getProfile: function() {
            const self = this;

            self.$axios
                .get(`/${self.type}/profile`)
                .then(response => {
                    if(response.status == 200){
                        self.data = response.data;
                        
                        self.$nextTick(() => {
                            self.$emit('loaded');
                            self.loaded = true;

                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        updateProfile: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;
                
                self.$axios
                    .put(`/${self.type}/profile`, self.data)
                    .then(response => {
                        if(response.status == 200) {
                            self.setToken(response.data.token);

                            self.$toasted.success(
                                self.$t('notifications.workProviderProfileUpdateSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.$nextTick(() => {
                                self.loading = false;
                                self.formValidated = false;
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        translate_age_group: function(key) {
            const self = this;
            return self.$t(`profile.${self.type}.card.age_group.values.${key}`)
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        display_notice: function() {
            const self = this;
            self.$toasted.info(
                self.$t('getstarted.steps.seven.notice'),
                { icon: 'circle-exclamation' }
            );
        }
    },

    mounted() {
        const self = this; self.getProfile();
    }
}
</script>

