<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded.user && loaded.common }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t('pages.profile.title')}}</h1>
            
            <div class="cta-container wide responsive desktop">
                <div    class="cta cta-secondary cta-slim" 
                        @click="showEmailModal = true">{{$t('pages.profile.ctas.email')}}</div>

                <div    class="cta cta-outline dark cta-slim"
                        :class="{disabled: !accountIsVerified}"
                        @click="showPasswordModal = true">{{$t(changePasswordButtonLabel)}}</div>
                
                <div    class="cta cta-error cta-slim" id="profile_delete"
                        @click="showDeleteAccountModal = true">{{$t('pages.profile.ctas.delete')}}</div>
            </div>
        </div>

        <div id="with-sticky-header" class="row top-row with-sticky-header">
            <div class="left">
                <UserProfileCard @loaded="loaded.user = true" />
                <WorkProviderProfileCard v-if="isWorkProvider" @loaded="loaded.common = true" />

                <CommonProfileCard v-if="is_person && isAllowedWorker" @loaded="loaded.common = true" type="worker" />

                <div v-if="loaded.user && loaded.common" class="card card-profile-actions responsive mobile">
                    <div class="card-heading border-bottom">
                        <p class="title">{{$t('profile.user.card.actions.title')}}</p>
                    </div>

                    <div class="cta cta-secondary cta-slim" @click="showEmailModal = true">{{$t('pages.profile.ctas.email')}}</div>
                    <div :class="['cta cta-outline dark cta-slim', {disabled: !accountIsVerified}]" @click="showPasswordModal = true">{{$t(changePasswordButtonLabel)}}</div>
                    <div class="cta cta-error cta-slim" id="profile_delete" @click="showDeleteAccountModal = true">{{$t('pages.profile.ctas.delete')}}</div>
                </div>

                <div v-else class="card loading"></div>
            </div>
        </div>
        
        <UpdateEmailModal :active="showEmailModal" @closeModal="showEmailModal = false"/>
        <UpdatePasswordModal :active="showPasswordModal" @closeModal="showPasswordModal = false"/>
        <DeleteAccountModal :active="showDeleteAccountModal" @closeModal="showDeleteAccountModal = false"/>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '../../../components/Layout.vue';
import UserProfileCard from '../../../components/Cards/Profile/UserProfileCard.vue'
import WorkProviderProfileCard from '../../../components/Cards/Profile/WorkProviderProfileCard.vue'
import CommonProfileCard from '../../../components/Cards/Profile/CommonProfileCard.vue'
import UpdateEmailModal from '../../../components/Cards/Profile/UpdateEmailModal.vue'
import UpdatePasswordModal from '../../../components/Cards/Profile/UpdatePasswordModal.vue'
import DeleteAccountModal from '../../../components/Cards/Profile/DeleteAccountModal.vue'

export default {
    name: 'Profile',

    components: {
        Layout,

        UserProfileCard,
        WorkProviderProfileCard,
        CommonProfileCard,
        UpdateEmailModal,
        UpdatePasswordModal,
        DeleteAccountModal
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            showEmailModal: false,
            showPasswordModal: false,
            showDeleteAccountModal: false,

            routeName: 'profile',
            classes: {
                dashboard: true,
                profile: true
            },

            loaded: {
                user: false,
                common: false
            }
        }
    },

    computed: {
        ...mapGetters([
            'isWorkProvider',
            'is_person',
            'isAllowedWorker',
            'isAllowedVolunteer',
            'accountIsVerified'
        ]),

        changePasswordButtonLabel: function() {
            const self = this; return self.accountIsVerified ? 'pages.profile.ctas.password.default' : 'pages.profile.ctas.password.disabled'
        }
    }
};
</script>