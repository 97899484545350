<template>
    <div class="modal password" :class="{active: active}">
        <div class="modal-card">
            <div class="modal-body">
                <div class="input-container">
                    <p>{{$t('profile.updatePassword.card.password.current.title')}}</p>

                    <input  :class="['input input-text', error('currentPassword')]"
                            :type="pwd_field_type"
                            v-model="data.currentPassword" 
                            @keyup.enter="updatePassword()">

                    <FormError :data="validation.currentPassword" />
                </div>

                <div class="input-container">
                    <p>{{$t('profile.updatePassword.card.password.new.title')}}</p>

                    <div class="password-container">
                        <input  :class="['input input-text', error('newPassword')]"
                            :type="pwd_field_type"
                            v-model="data.newPassword" 
                            @keyup.enter="updatePassword()">

                            <span class="show-password cta cta-form dark" @click="change_password_field_type()"><font-awesome-icon :icon="['fa', pwd_field_type === 'password' ? 'eye' : 'eye-slash']" /></span>
                        </div>

                    <FormError :data="validation.newPassword" />
                </div>
            </div>

            <div class="cta-container">
                <div class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="updatePassword()">{{$t('profile.updatePassword.card.submit.label')}}</div>
                <div class="cta cta-neutral cta-slim" @click="closeAndResetModal()">{{$t('language.cancel')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: 'UpdatePasswordModal',

    components: {
        FormError
    },

    props: {
        active: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            data: {
                currentPassword: '',
                newPassword: ''
            },

             validation: {
                currentPassword: {
                    error: false,
                    key: 'errors.validation.emailInvalid'
                },
                newPassword: {
                    error: false,
                    key: 'errors.validation.passwordInvalid'
                }
            },

            pwd_field_type: 'password',

            formValidated: false,
            loading: false,
        }
    },

    watch: {
        active: function() {
            const self = this;
            if(self.active) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    methods: {
        ...mapActions([
            'logout'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;

            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
                self.validation[val].key = 'errors.validation.fieldMandatory'

                if(!self.data[val] )
                    self.validation[val].error = true;
                else if(self.data[val].length < 10) {
                    self.validation[val].error = true;
                    self.validation[val].key = 'errors.validation.passwordInvalid';
                }
            });

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });

        },

        updatePassword: function() {
            const self = this;

            self.validate_mandatory_fields()
            if(self.formValidated) {
                self.loading = true;

                const data = {
                    currentPassword: self.data.currentPassword,
                    newPassword: self.data.newPassword
                }
                
                self.$axios
                    .put(`/user/password`, data)
                    .then(response => {
                        if(response.status == 200){
                            self.$toasted.success(
                                self.$t('notifications.passwordUpdateSuccess'),
                                { icon: 'circle-check' }
                            );

                            setTimeout(function(){ self.logout(self.$router) }, 3000);

                            self.$nextTick(() => {
                                self.formValidated = false;
                                self.closeAndResetModal();
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        closeAndResetModal: function() {
            const self = this;

            self.loading = false;
            self.data.currentPassword = '';
            self.data.newPassword = '';

            self.$emit('closeModal');
        },

         change_password_field_type: function() {
            const self = this;
            self.pwd_field_type = self.pwd_field_type === 'password' ? 'text' : 'password'
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    }
}
</script>