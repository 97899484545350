<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t('sidebar.settings')}}</h1>
        </div>

        <div id="with-sticky-header" class="row top-row with-sticky-header">
            <div class="left">
                <NotificationPreferencesCard v-if="isWorkProvider" @loaded="loaded = true" type="work-provider" />
                <NotificationPreferencesCard v-if="is_person && isAllowedWorker" @loaded="loaded = true" type="worker" />
                <NotificationPreferencesCard v-if="is_person && isAllowedVolunteer" @loaded="loaded = true" type="volunteer" />
                
                <div v-if="!loaded" class="card loading"></div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';

import Layout from '../../components/Layout.vue';
import NotificationPreferencesCard from '../../components/Cards/Settings/NotificationPreferencesCard.vue';

export default {
    name: 'Profile',

    components: {
        Layout,
        NotificationPreferencesCard
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'settings',
            loaded: false,
            classes: {
                dashboard: true,
                settings: true
            }
        }
    },

    computed: {
        ...mapGetters([
            'isWorkProvider',
            'is_person',

            'isAllowedWorker',
            'isAllowedVolunteer'
        ])
    }
};
</script>